import React, { useState } from "react";
import { Radio } from 'antd';
import { useQuery, gql, useMutation } from "@apollo/client";
import { MainRight, MainLeft, Container } from "./styled";
import DropInputField from "./input-drag";
import "antd/dist/antd.min.css";
import "./index.css";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  notification,
  message, Dropdown, Menu, Spin
} from "antd";
import _ from "lodash";
import { PlusOutlined, CloseOutlined, CheckOutlined, PlusCircleOutlined, CaretDownOutlined } from "@ant-design/icons";

import AddTask from "../wf-def/add-input-task";
import { WorkflowNotification } from "../notification";
const { TextArea } = Input;
const { Option } = Select;

const RUN_AFTER_DELAY = 'RUN_AFTER_DELAY';
const RUN_IMMEDIATELY = 'RUN_IMMEDIATELY';
const CREATE_WORKFLOW_TIME_BASED = gql`
  mutation CreateWorkflow(
    $name: String!
    $description: String
    $workspaceId: String
    $taskExecutorId: String
    $cron: String
    $wfRunConditionType:WFRunConditionType, 
    $workflowCodeArtifact: String
    $codeArtifactFileName: String
    $cronType: WFCronType
    $definition:String
    $notifications:[InputWorkflowNotification],
    $envVariables:[InputWFEnvVariable],
    $cesiumUserIds: [String!]
  ) {
    createWorkflow(
      name: $name
      description: $description
      workspaceId: $workspaceId
      taskExecutorId: $taskExecutorId
      workflowCodeArtifact: $workflowCodeArtifact
      wfRunConditionType:$wfRunConditionType
      codeArtifactFileName: $codeArtifactFileName
      wfSchedule: { cron: $cron, cronType: $cronType }
      notifications:$notifications,
      envVariables:$envVariables,
      definition:$definition,
      cesiumUserIds: $cesiumUserIds
    ) {
      success
      errors {
        errorCode
        errorMessage
      }
      result {
        ... on Workflow {
          workflowId
        }
      }
    }
  }
`;

const CREATE_WORKFLOW_TIGGER_BASED = gql`
  mutation CreateWorkflow(
    $name: String!
    $description: String
    $workspaceId: String
    $taskExecutorId: String
    $workflowCodeArtifact: String
    $codeArtifactFileName: String
    $wfRunConditionType:WFRunConditionType, 
    $notifications:[InputWorkflowNotification],
    $envVariables:[InputWFEnvVariable],
    $workflowId:String!
    $definition:String
    $workflowTriggerType: String
    $workflowTriggerDelay: String
  ) {
    createWorkflow(
      name: $name
      description: $description
      workspaceId: $workspaceId
      wfRunConditionType:$wfRunConditionType
      taskExecutorId: $taskExecutorId
      workflowCodeArtifact: $workflowCodeArtifact
      codeArtifactFileName: $codeArtifactFileName
      notifications:$notifications
      envVariables:$envVariables
      wfCompletionRunCondition:{
        workflowId:$workflowId
        workflowTriggerType: $workflowTriggerType
        workflowTriggerDelay: $workflowTriggerDelay
      }
      definition:$definition
    ) {
      success
      errors {
        errorCode
        errorMessage
      }
      result {
        ... on Workflow {
          workflowId
        }
      }
    }
  }
`;
const CREATE_WORKFLOW_MANUVAL_BASED = gql`
  mutation CreateWorkflow(
    $name: String!
    $description: String
    $workspaceId: String
    $taskExecutorId: String
    $wfRunConditionType:WFRunConditionType,
    $workflowCodeArtifact: String
    $codeArtifactFileName: String
    $notifications:[InputWorkflowNotification],
    $envVariables:[InputWFEnvVariable],
    $definition:String,
    $cesiumUserIds: [String!]
  ) {
    createWorkflow(
      name: $name
      description: $description
      workspaceId: $workspaceId
      taskExecutorId: $taskExecutorId
      workflowCodeArtifact: $workflowCodeArtifact
      codeArtifactFileName: $codeArtifactFileName
      notifications:$notifications,
      envVariables:$envVariables,
      wfRunConditionType:$wfRunConditionType,
      definition:$definition,
      cesiumUserIds: $cesiumUserIds
    ) {
      success
      errors {
        errorCode
        errorMessage
      }
      result {
        ... on Workflow {
          workflowId
        }
      }
    }
  }
`;

const GET_CESIUM_USERS = gql`
query {
  getTenantUsers {
    id
    tenantUserId
    name
    email
  }
}
`;

const GET_TASK_EXECUTERS = gql`
  query getTaskExecutors($workspaceId: String,$search: String) {
    getTaskExecutors(workspaceId: $workspaceId,search: $search) {
      nodes {
        id
        __typename
        ... on TaskExecutor {
          taskExecutorId
          name
        }
      }
    }
  }
`;
const GET_WORKFLOWS = gql`
  query($workspaceId: String, $search: String,$numberofItems:Int) {
    getWorkflows(search: $search, workspaceId: $workspaceId,first: $numberofItems) {
      pageInfo {
        prevPageCursor
        nextPageCursor
      }
      nodes {
        ... on Workflow {
          id
          name
          description
          status
          wfSchedule {
            cron
            cronType
          }
          taskExecutor {
            taskExecutorId
            name
          }
          lastUpdated
        }
      }
    }
  }
`;

const GET_MY_WORKSPACES = gql`
  query USER_WORKSPACES($search: String,$after: String,$before: String,$numberofItems:Int) {
    getWorkspaces(search: $search, first: $numberofItems,after: $after,before: $before) {
      pageInfo {
        prevPageCursor
        nextPageCursor
      }
      nodes {
        id
        ... on Workspace {
          workspaceId
          name
        }
      }
    }
  }
`;

type DrawerState = "visible" | "hidden";
const props = {
  name: 'file',
  multiple: true,
  onChange(info: any) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
// @ts-ignore

export const CreateWorkflowForm = (props) => {
  const [form] = Form.useForm();
  // const { refetch } = props;
  const [workspaceId, setWorkspaceIdState] = useState<string>("");
  const [cesiumUserIds, setCesiumUserIdsState] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [numberofItems, setNumberofItems] = useState<any>(50);
  const [searchTaskExecutorFilter, setSearchTaskExecutorFilter] = useState<string>("");
  const [fileData, setFileData] = useState<any>(null);
  const [imageFileData, setImageFileData] = useState<any>(null);
  const [filter, setFilter] = useState<string>("");
  const [flowSearchFilter, setFlowSearchFilter] = useState<string>("");
  const [definitionData, setDefinitionData] = useState<any>([]);
  const [inputData, setInputData] = useState<any>([]);
  const [isValidWorkflowName, setIsValidWorkflowName] = useState<boolean>(false);
  const [workflowNotifications, setWorkflowNotifications] = useState<any[]>([]);

  const { data: dataTask, loading: loadingTask } = useQuery(GET_TASK_EXECUTERS, {
    variables: { workspaceId: workspaceId, search: searchTaskExecutorFilter },
    skip: !workspaceId,
  });

  const { data: dataUsers, loading: loadingUsers } = useQuery(GET_CESIUM_USERS, {
    variables: {},
  });

  const { data: dataWorkspace, loading: loadingWorkSpace, fetchMore } = useQuery(GET_MY_WORKSPACES, {
    variables: { search: searchFilter, numberofItems: numberofItems },
  });
  const {
    loading: loadingWorkflows,
    error: errorWorkflows,
    data: dataWorkflows,
    refetch,
  } = useQuery(GET_WORKFLOWS, {
    variables: { workspaceId: filter, search: flowSearchFilter, numberofItems: numberofItems },
  });


  const [disabledState, setDisabledState] = useState<boolean>(true);
  const [disabledWorklow, setdisabledWorklow] = useState<boolean>(true);

  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [value, setValue] = React.useState<any>();

  const [createWorkflowGQL, { data }] = useMutation(value === "WORKFLOW_COMPLETE" ? CREATE_WORKFLOW_TIGGER_BASED : value === "MANUAL" ? CREATE_WORKFLOW_MANUVAL_BASED : CREATE_WORKFLOW_TIME_BASED);

  const [drawerState, setDrawerState] = useState<DrawerState>("hidden");
  const [envVariableData, setEnvVariableData] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<any>([]);
  const [activeKeyDropDown, setActiveKeyDropDown] = useState<any>("");
  const [onValidateForm, SetOnValidateForm] = useState<any>("");
  const [currentFormData, setCurrentFormData] = useState<any>({});
  const [workflowTriggerType, setWorkflowTriggerType] = useState<String>("");
  const [workflowTriggerDelay, setWorkflowTriggerDelay] = useState<any>(null);

  const onClose = () => {
    setDrawerState("hidden");
    form.resetFields();
    setFileData(null);
    setDisabledState(true);
  };

  const getFinalDefinition = () => {


    return { inputs: inputData, tasks: definitionData };
  }
  const onFinish = (e: any) => {
    //Do not submit if workflow name is not valid
    if (!isValidWorkflowName) {
      return;
    }

    SetOnValidateForm(false);
    const data = getFinalDefinition();


    if (!_.isEmpty(data) && !_.isEmpty(_.get(e, 'name')) && !_.isEmpty(_.get(e, 'description')) && !_.isEmpty(_.get(e, 'workspaceId'))) {

      let details: any = {
        name: _.get(e, 'name'),
        description: _.get(e, 'description'),
        workspaceId: _.get(e, 'workspaceId'),
        taskExecutorId: _.get(e, 'taskExecutorId'),
        wfRunConditionType: _.get(e, 'wfRunConditionType'),
        workflowCodeArtifact: fileData?.workflowCodeArtifact || "",
        codeArtifactFileName: fileData?.codeArtifactFileName || "",
        notifications: workflowNotifications,
        envVariables: envVariableData,
        definition: JSON.stringify(data),
        cesiumUserIds: _.get(e, 'cesiumUserIds')

      }

      if (_.get(e, 'cron')) {
        details['cron'] = _.get(e, 'cron')
        details.cronType = _.get(e, 'cronType')
      }
      if (_.get(e, 'workflowId')) {
        details['workflowId'] = _.get(e, 'workflowId');
        details['workflowTriggerType'] = workflowTriggerType;
        if (workflowTriggerType === RUN_AFTER_DELAY) {
          details['workflowTriggerDelay'] = workflowTriggerDelay;
        }
      }


      setLoadingButton(true);
      createWorkflowGQL({
        variables: details,
      })
        .then((response) => {

          if (_.get(response, 'data.createWorkflow.success') === true) {
            setLoadingButton(false);
            setWorkflowNotifications([]);
            setEnvVariableData([])
            setWorkflowTriggerType('');
            setWorkflowTriggerDelay(null);
            form.resetFields();
            setDefinitionData([])
            setCurrentFormData({})
            //setCesiumUserIds([])
            onClose();
            props.refetch();
            notification["success"]({
              message: "New Workflow created",
              description: "Your New Workflow is Ready!",
            });
          } else if (_.get(response, 'data.createWorkflow.success') === false) {
            setLoadingButton(false);
            if (_.get(response, 'data.createWorkflow.errors[0].errorMessage')) {
              notification.error({
                message: 'Error',
                description: _.get(response, 'data.createWorkflow.errors[0].errorMessage'),
              });
            }
          }

        })
        .catch((error) => {
          notification["error"]({
            message: "Some Error Occured",
            description: "Some error occured while creating new Workflow!",
          });
          setLoadingButton(false);
        });
    }

  };

  const showDrawer = () => {
    setDrawerState("visible");
  };

  const handleChange = (id: any) => {
    setWorkspaceIdState(id);
    form.resetFields(["taskExecutorId"]);
    setDisabledState(false);
    setFilter(id)
    //Reset LDAP task remote, when workspace is changed.
    resetLDAPTaskRemote();
  };
  const handleCesiumUserIdsChange = (id: any) => {
    setCesiumUserIdsState(id);
  }

  const resetLDAPTaskRemote = () => {

    setDefinitionData((prevDefinitionData: []) => {
      prevDefinitionData.forEach((definition: any) => {
        if (definition && definition.type === "LDAPTask") {
          definition.ldapRemoteSystemId = '';
        }
      });
      return prevDefinitionData;
    })
  }

  const onWsSearch = (val: string) => {
    setSearchFilter(val);
  };

  const changeFileData = (value: string) => {
    setFileData(value);
    // setSearchTaskExecutorFilter(value);
  };

  const changeTaskExecutorData = (value: string) => {
    // setFileData(value);
    setSearchTaskExecutorFilter(value);
  };

  const loadMoreWorkSpace = (e: any, data: any) => {
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (_.get(data, 'getWorkspaces.pageInfo.nextPageCursor')) {
        // setcursor(_.get(data,'getWorkspaces.pageInfo.cursor'))
        let number_of_items = numberofItems
        let final = number_of_items + 10
        setNumberofItems(final)
      }

    }
  }

  const addEnvVariable = () => {
    if (_.isArray(envVariableData) === true && _.size(envVariableData) === 0) {
      setEnvVariableData([{ name: '', value: '' }])
    } else if (_.isArray(envVariableData) === true && _.size(envVariableData) > 0) {
      let size_data = _.size(envVariableData) - 1
      if (_.get(envVariableData[size_data], "name")) {
        let prv_current_data = [...envVariableData, { name: '', value: '' }]
        setEnvVariableData(prv_current_data)
      }
    }
  }

  const onChangeEnvVaribale = async (e: any, type: String, index: any) => {
    let update_env = envVariableData
    if (type === 'name') {
      update_env[index].name = e
    } else {
      update_env[index].value = e
    }
    let final_data = [...update_env]
    setEnvVariableData(final_data)
  }
  const removeEnvVariable = (index: any) => {
    let array = [...envVariableData];
    _.remove(array, (item, i) => i === index);
    setEnvVariableData(array)

  }


  const envVariableView = (data: any) => {

    return _.map(data, (data, index: number) => {
      return <div style={{ marginTop: index === 0 ? 0 : 15 }} key={index} className="KeyValueContainerHeader">
        {
          index === 0 &&
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="input-label">Key</div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="input-label">Value</div>
            </Col>
          </Row>
        }
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Input onChange={(e) => onChangeEnvVaribale(_.get(e, 'target.value'), 'name', index)} value={_.get(data, 'name')} defaultValue={_.get(data, 'name')} placeholder="TEMP" />
          </Col>
          <Col className="gutter-row" span={12}>
            <div className="KeyValueInputClose">
              <Input onChange={(e) => onChangeEnvVaribale(_.get(e, 'target.value'), 'value', index)} value={_.get(data, 'value')} defaultValue={_.get(data, 'value')} placeholder="C:\Users\91935\App Data\Local\Temp" />
              <CloseOutlined onClick={() => removeEnvVariable(index)} />
            </div>
          </Col>
        </Row>
      </div>
    })


  }

  const onFinishFailed = (e: any) => {

  }
  const onChangeSubmitForm = () => {
    setActiveKey(['1', '2', '3', '4']);
    form.submit();
  }
  function renderContent(tab: any) {
    switch (tab) {
      case 'SCHEDULED_CRON':
        return <div>
          <Form.Item
            name="cron"
            label="Schedule"
            rules={[{ required: true, message: "Schedule" }]}
          >
            <Input placeholder="0 8 * * *" />
          </Form.Item>
          <Form.Item
            name="cronType"
            label="Schedule Type"
            rules={[{ required: true, message: "Schedule Type" }]}
          >
            <Select placeholder="Please enter the Schedule Type">
              <Option value="UNIX">UNIX</Option>
              <Option value="QUARTZ">QUARTZ</Option>
            </Select>
          </Form.Item>
        </div>;
      case 'WORKFLOW_COMPLETE':
        const changeWorkflowTriggerType = (e: any) => {
          setWorkflowTriggerType(e.target.value);
        };

        const changeWorkflowTriggerDelay = (e: any) => {
          setWorkflowTriggerDelay(e.target.value);
        };

        return <div>

          <Form.Item
            name="workflowId"
            label="Select a Workflow"
            rules={[{ required: true, message: "Select a Workflow" }]}
            className="mb-0"
          >
            <Select
              // disabled={disabledWorklow}
              onSelect={(e: any) => form.setFieldsValue({ workflowId: e })
              }
              loading={loadingWorkflows}
              showSearch
              placeholder="Select a Workflow"
              optionFilterProp="children"
            >
              {dataWorkflows?.getWorkflows?.nodes?.map(
                (workspace: { id: string; name: string }) => (
                  <Option value={workspace.id} key={workspace.id}>
                    {workspace.name}
                  </Option>
                )
              )}
            </Select>
            <div className="SelectOverflowDec">This trigger will work after the selected workflow has been completed</div>
          </Form.Item>
          <Radio.Group style={{ margin: "20px" }} onChange={changeWorkflowTriggerType} value={workflowTriggerType} >
            <Radio value={RUN_IMMEDIATELY} >Run immediately</Radio>
            <Radio value={RUN_AFTER_DELAY} >After delay of</Radio>
          </Radio.Group>
          {workflowTriggerType === RUN_AFTER_DELAY ?
            <Input type="text" placeholder="seconds" style={{ width: '150px' }} value={workflowTriggerDelay} onChange={changeWorkflowTriggerDelay} /> : null}
        </div>;
      case 'MANUAL':
        return <Form.Item
          name="SelectFlowId"
          className="mb-0"
        ><div className="TriggerManualText"><CheckOutlined style={{ marginRight: '5px', color: '#0C9E84' }} /><span>Tirgger set to manual.</span></div>
        </Form.Item>;
    }
  }
  const RedioChange = (e: any) => {
    setValue(e.target.value);
  };
    
  const render = () => {

    return (
      <>
        <Button type="primary" onClick={showDrawer}>
          <PlusOutlined /> New Workflow
        </Button>

        <Drawer
          title="Create a new Workflow"
          width={1040}
          onClose={onClose}
          visible={drawerState === "visible"}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button disabled={loadingButton} onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button disabled={loadingButton} onClick={() => onChangeSubmitForm()} htmlType="submit" type="primary" loading={loadingButton}>
                Create
              </Button>
            </div>
          }
        >
          <Form form={form} layout="vertical" onFinishFailed={onFinishFailed} onFinish={onFinish} className="workflow-collapse-container">
            <Container>
              <MainLeft>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Workflow name is required" }, {
                    message: 'Please enter a valid workflow name. Only lower case letters, numbers and underscores are allowed',
                    validator: (_, value) => {
                      const re = new RegExp('^[a-z0-9_]+$');
                      if (re.test(value)) {
                        setIsValidWorkflowName(true);
                        return Promise.resolve();
                      } else {
                        setIsValidWorkflowName(false);
                        return Promise.reject('Incorrect format')
                      }
                    }
                  }]}
                >
                  <Input placeholder="Some name" />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: "Workflow Description" }]}
                >
                  <TextArea rows={2} value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. " />
                </Form.Item>
                <Form.Item
                  name="workspaceId"
                  label="Workspace"
                  rules={[{ required: true, message: "Workspace Name" }]}
                >
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    placeholder="Please enter the associated Work Space"
                    onSelect={(id: any) => handleChange(id)}
                    showSearch

                    onSearch={(e: string) => onWsSearch(e)}
                    // loading={loadingWorkSpace}
                    notFoundContent={loadingWorkSpace ? <Spin size="small" /> : "No data"}
                    onPopupScroll={(e) => loadMoreWorkSpace(e, dataWorkspace)}

                  >
                    {dataWorkspace?.getWorkspaces?.nodes?.map(
                      (workspace: { id: string; name: string }) => (
                        <Option value={workspace.id} key={workspace.id}>
                          {workspace.name}
                        </Option>
                      )
                    )}

                  </Select>
                </Form.Item>
                <Form.Item
                  name="taskExecutorId"
                  label="Associate Task Executor"
                  rules={[{ required: true, message: "Task Executor Name" }]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder="Please enter the associated Task Executor"
                    disabled={disabledState}
                    showSearch
                    onSearch={(e: string) => changeTaskExecutorData(e)}
                    loading={loadingTask}
                    notFoundContent={loadingTask ? <Spin size="small" /> : "No Task Executor found"}

                  >
                    {dataTask?.getTaskExecutors.nodes.map(
                      (taskExecutor: {
                        taskExecutorId: string;
                        name: string;
                      }) => (
                        <Option
                          value={taskExecutor.taskExecutorId}
                          key={taskExecutor.taskExecutorId}
                        >
                          {taskExecutor.name}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="wfRunConditionType"
                  className="TriggerRadioGroup"
                >

                  <Radio.Group onChange={RedioChange} value={value}>
                    <Radio value={'SCHEDULED_CRON'}>Time Based</Radio>
                    <Radio value={'WORKFLOW_COMPLETE'}>Workflow Trigger</Radio>
                    <Radio value={'MANUAL'}>Manual</Radio>
                  </Radio.Group>
                </Form.Item>
                <div>{renderContent(value)}</div>


                <Form.Item
                  name="codeArtifact"
                  label="Code Artifact"
                  className="mb-0"
                >
                  <DropInputField changeFileData={changeFileData} />

                </Form.Item>

                <Form.Item
                  name="cesiumUserIds"
                  label="User Permissions"
                  
                >
                  <Select mode="multiple" allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    placeholder="User Permissions"
                    onSelect={(id: any) => handleCesiumUserIdsChange(id)}
                  >
                    {dataUsers?.getTenantUsers?.map(
                      (user: { id: string; name: string }) => (
                        <Option value={user.id} key={user.id}>
                          {user.name}
                        </Option>
                      )
                    )}

                  </Select>
                </Form.Item>
                <WorkflowNotification  setWorkflowNotifications={setWorkflowNotifications} workflowNotifications={workflowNotifications} />
              </MainLeft>
              <MainRight>

                <Form.Item
                  name="Workflow_Definition"
                  label="Workflow Definition"
                  rules={[{ message: "Workflow Definition" }]}
                  className="w-100 mb-0"
                >

                  <div className="KeyValueContainer" style={{ padding: 0, border: 'none', marginTop: '18px' }}>


                  </div>
                </Form.Item>
                <AddTask workspaceId={workspaceId} definitionData={definitionData} setDefinitionData={setDefinitionData} inputData={inputData} setInputData={setInputData} />

                <Form.Item
                  name="Workflow_Definition"
                  label="Environment Variable"
                  rules={[{ message: "Environment Variable" }]}
                  className="w-100 mb-0"
                >

                  <Button onClick={() => addEnvVariable()} icon={<PlusCircleOutlined />} >Add Variable</Button>
                  <div className="KeyValueContainer" style={{ padding: 0, border: 'none', marginTop: '18px' }}>
                    {envVariableView(envVariableData)}
                  </div>
                </Form.Item>
              </MainRight>
            </Container>
          </ Form>

        </Drawer>
      </>
    );
  }
  return render()
}
